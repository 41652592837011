<template>
  <v-content class="login px-3">
    <v-row class="text-center" align-content="center" style="height:100vh">
      <v-col>

        <div v-if="password_integration_status !== 1">
          <h2>いずれかのサービスを</h2>
          <h2>ご利用ですか？</h2>
          <div class="row pt-5">
            <div class="col-7">
              <img class="photo py-1" src="@/assets/icon_ec.png" width="100%"/>
              <p>公式オンラインショップ</p>
            </div>
            <div class="col-5">
              <img class="photo" src="@/assets/icon_app.png" width="50%" style="border-radius:10px; border: solid 1px grey"/>
              <p>やまや公式アプリ</p>
            </div>
          </div>
          <div class="radius1">
            <h3>ご利用ではない方</h3>
            <p class="fontsize12">ご登録いただくと、お買い物金額に応じてポイントやスタンプが獲得できます。店舗ではアプリのQRコードをご提示いただくだけで、ポイントやスタンプが獲得できます。獲得したポイントはお買い物時にご利用いただけます。</p>
            <v-btn rounded depressed class="default_button3 mb-2" to="/register_email">新規会員登録</v-btn>
          </div>
          <h3 class="pt-7">ご利用の方</h3>
          <v-btn rounded depressed class="default_button4 mb-7" to="/login">ログインする</v-btn>
        </div>

        <div v-else>
          <div class="text-left px-6">
            <p>いつもやまやアプリをご利用いただきありがとうございます。</p>
            <p>アプリとオンラインショップのアカウントをまとめることにより、便利なお買い物体験と特典をお楽しみいただけます。</p>
            <div class="text-center py-3">
              <img class="photo" src="@/assets/password_mismatched.png" width="80%"/>
            </div>
            <p>下のボタンよりパスワードの再設定をお願いします。</p>
          </div>
          <v-btn  rounded class="default_button3 mb-10" @click="lostPassword()">パスワード再設定</v-btn>
        </div>

        <p class="inquiry">
          <a href="https://www.yamaya.com/corporate/contact/">お問い合わせ</a>
        </p>
      </v-col>
    </v-row>
  </v-content>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
      init: this.storageGet('*'),
      password_integration_status: null,
    };
  },
  async created () {
    this.storageSessionDelete('*'); // sessionStorageの削除
    this.password_integration_status = this.init.password_integration_status
  },
  methods: {
    async lostPassword() {
      const callback = encodeURIComponent(process.env.VUE_APP_BASE_URL + '/login')
      this.showEcPage('/password.html', 'callback=' + callback)
    },
  }
};
</script>

<style scoped>
.login {
  height: 100vh;
  margin: auto;
}
.button {
  font-size: 0.95rem;
  font-weight: 300 !important;
  height: 2.4rem !important;
  border: solid 1px var(--main_color) !important;
  max-width: 14rem;
  min-width: initial !important;
  margin: auto;
  font-weight: initial !important;
  opacity: 1;
}
.button1 {
  letter-spacing: 0rem;
}
.no-member {
  letter-spacing: 0.1rem;
}
.default_button3::before {
  opacity: 0 !important;
}
.inquiry {
  font-size: 0.875rem;
  transition: 0.15s;
  outline-style: none;
  text-decoration: underline;
}

.fontsize12>>>label {
  font-size: 12px;
}

.inquiry a {
  color: #333 !important;
}

.radius1 {
  border: 1px solid #E90606;
  border-radius: 10px;
  width: 96%;
  padding: 10px;
  display: inline-block;
}
</style>
